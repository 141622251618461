.App {
  height: 100vh;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.sidebar{
  width: 25%;
  height: 100%;
  float: left;
}

@media screen and (max-width: 600px){

  .App{
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
    justify-content: center;
  }

}