.body_container{
    height: 100%;
    width: 100%;
}

.sidebar{
    height: 100%;
    width: 28%;
    display: inline-block;
    justify-content: center;
    margin: 30px;
    background-color: #f6f6f696;

}

.filter_price{
    width: 100%;
    height: 50px;
    border: 1px solid rgb(202, 202, 202);
    background-color: rgba(1, 0, 3, 0.72);
    padding: auto;
    display: flex;
    justify-content: space-between;
}

.filter_price h3{
    color: white;
    margin-left: 15px;
    font-size: 14px;
}

.search_div{
    width: 100%;
    height: 40px;
    border: 1px solid rgb(202, 202, 202);
    display: flex;
    justify-content: space-evenly;
}

#search_input{
    height: 30px;
    width: 80%;
    border: 1px solid rgb(202, 202, 202);
    font-size: 16px;
    padding-left: 10px;
    border-radius: 20px;
}

#search_input:focus{
    outline: none;
}

.search_btn_mobile{
    margin-top: 5px;
    height: 25px;
    width: 25px;
}

.apply_btn{
    height: 40px;
    width: 100px;
    color: white;
    background-color: rgb(30, 0, 162);
    float: right;
    margin-top: 5px;
    margin-right: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
}

.apply_btn:hover{
    transition: 0.5s;
    background-color: rgb(255, 255, 255);
    color:  rgb(30, 0, 162);
    border: 2px solid rgb(30, 0, 162);
    font-weight: 500;
}

.home_container{
    height: 100%;
    width: 100%;
    display: flex;
}

.search_div_2{
    display: none;
}




@media screen and (max-width: 600px){


    .sidebar{
        max-width: 0px;
        max-height: 0px;
        top: 0;
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        justify-content: center;
        text-align: center;
        background-color: red;
    }
    

    .top_options{
        height: 50px;
        visibility: visible;
        display: flex;
        justify-content: space-evenly;
    }

    .filter_price{
        display: none;
    }

    .top_mobile-bar{
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        display: inline-block;
        justify-content: center;
        text-align: center;
        background-color: rgba(1, 0, 3, 0.72);
        border-bottom: 1px solid rgb(183, 183, 183);
    }

    .search_div_2{
        visibility: visible;
        width: 100%;
        display: flex;
    }

    #search_input{
        width: 74%;
        display: block;
        border-radius: 10px;
        font-size: 17px;
        margin-left: 10px;
        margin-top: 5px;
    }

    .search_btn_mobile{
        width: 25px;
        height: 25px;
        border: none;
        color: white;
        margin-left: 10px;
        margin-top: 3px;
        background-color: white;
        border-radius: 20px;
        padding: 7px;
    }

    .item_div_main{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        background-color: rgb(238, 238, 238);
    }

    .body_container{
        width: 100%;
        display: inline-block;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .search_div{
        display: none;
    }
}