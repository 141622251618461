
.main_edit_container{
    width: 100%;
    height: 100%;
}

.edit_image_container{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.file_btn{

    width: 80px;
}

#edit_image{
    max-height: 200px;
    max-width: 200px;
    border: 1px solid black;
    border-radius: 20px;
    margin-left: 20px;
    object-fit: contain;
}

.fields_div{
    width: 50%;
    height: fit-content;
}

.fields_div input{
    width: 200px;
    height: 30px;
    margin-top: 10px;
    margin-left: 10px;
}

.save_btn{
    height: 40px;
    width: 100px;
    background-color: rgb(0, 115, 255);
    border: none;
    border-radius: 20px;
    color: white;
}

.file_btn{
    height: 20px;
    min-width: 120px;
    border-radius: 10px;
}

@media screen and (max-width: 600px){

    .edit_image_container{
        display: inline-block;
        justify-content: center;
        text-align: center;
    }
}

