
.post_div{
    width: 300px;
    height: 520px;
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    border: 1px solid gray;
    padding: 0;
}

.item_list_header{
    height: 40px;
    width: 100%;
    text-align: center;
}

.item_list_header h3{
    font-size: 17px;
    font-weight: 500;
}

#item_image{
    width: 100%;
    max-height: 270px;
    object-fit: contain;
}

.item_list_details{
    display: block;
    text-align: center;
    justify-content: space-evenly;
}

.item_list_details h4{
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
}



.details_ul_div p{
    width: 100%;
    line-height: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item_list_details  span{
    color: rgb(26, 0, 176);
    font-size: 20px;
}

.car_detail_ul{
    margin-top: -10px;
    margin-bottom: 10px;
    list-style-type: circle;
}

.car_detail_ul li{
    text-align: center;
    text-indent: -100px;
    font-size: 15px;
}

.btn_div{
    display: flex;
    justify-content: center;
    text-align: center;
}

.whatsapp_btn{
    height: 40px;
    width: 120px;
    border-radius: 7px;
    display: flex;
    border: none;
    background-color: #128C7E;
    font-size: 15px;
    line-height: 40px;
    color: white;
    justify-content: center;

}

.whatsapp_btn:hover{
    color: #128C7E;
    background-color: white;
    border: 2px solid #128C7E;
    text-align: center;
}

#whatsapp_icon{
    margin-top: 5px;
}

.see_btn{
    height: 40px;
    width: 80px;
    border-radius: 7px;
    display: flex;
    border: none;
    font-size: 15px;
    line-height: 40px;
    color: white;
    background-color: #0465ba;
    margin-left: 10px;
    justify-content: center;
}

.see_more_img{
    height: 25px;
    width: 25px;
    margin-top: 5px;
    margin-right: 10px;
}

.main{
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.edit_btn{
    height: 40px;
    width: 80px;
    border-radius: 7px;
    border: none;
    background-color: green;
    font-size: 17px;
    color: white;
    margin-right: 10px;
}


.delete_btn{
    height: 40px;
    width: 80px;
    border-radius: 7px;
    border: none;
    background-color: red;
    font-size: 17px;
    color: white;
}

#article_icons{
    height: 20px;
    width: 19px;
    border: 1px solid rgb(158, 158, 158);
    border-radius: 30px;
    padding: 5px;
    margin-right: 15px;
}


@media screen and (max-width: 600px){

    .main{
        width: 100%;
        display: inline-block;
        text-align: center;
        justify-content: space-between;
        overflow-y: scroll;
        margin-top: 25%;   
    }

    .post_div{
        width: 95%;
        height: 480px;
        margin: auto;
        margin-top: 50px;
        border-radius:5px;
    }

    #item_image{
        max-width: 100%;
        max-height: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    #whatsapp_icon{
        height: 25px;
        width: 25px;
        margin-top: 5px;
        margin-right: 10px;
    }
    
    .item_list_header{
        height: 30px;
        width: 100%;
        margin-top: -20px;
        margin-bottom: 30px;
        text-align: center;
    }

    .item_list_header h3{
        font-size: 30px;
        font-weight: 600;
    }

    .item_list_details{
        max-height: fit-content;
    }

    .listing_img_div{
        padding: 0;
        width: 100%;
        height: 260px;
    }

    .item_list_details  span{
        color: rgb(26, 0, 176);
        font-size: 24px;
    }

    .details_ul_div{
        margin-bottom: 30px;
    }
}